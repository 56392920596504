import React from "react";
import { AutoLink } from "../helpers/AutoLink";
import { concatClassNames as cn } from "@sys42/utils";

import * as styles from "./styles.module.css";

/**


  A clickable image tile with a title and subtitle.
  Either `linkTo` or `linkHref` need to be provided.

  @property {string} title - Main text value.
  @property {string} subtitle - Secondary text value.
  @property {string} [linkTo] - Gatsby <Link /> prop.
  @property {string} [linkHref] - Html <a /> href.
  @property {()=><img />} staticImage - The background image.

*/
export function CategoryLinkCard(props) {
  const { title, subtitle, linkTo, linkHref, staticImage } = props;

  return (
    <div className={styles.categoryLinkCard}>
      <AutoLink className={styles.card} href={linkHref} to={linkTo}>
        <div className={styles.title}>{title}</div>
        <div className={cn(styles.subtitle, styles.subtitleCenter)}>
          {subtitle}
        </div>
        {staticImage?.(styles.image)}
      </AutoLink>
      <div className={cn(styles.subtitle, styles.subtitleBottom)}>
        {subtitle}
      </div>
    </div>
  );
}

/**


  CategoryLinkCard group container.

*/
CategoryLinkCard.Group = function CategoryLinkCardGroup(props) {
  const { className, ...restProps } = props;
  return <div className={cn(styles.group, className)} {...restProps} />;
};
