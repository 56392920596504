import React from "react";
import { graphql } from "gatsby";

import { concatClassNames as cn } from "@sys42/utils";

import { ButtonPrimaryLarge, Headline3Sans } from "@userbrain/website-ui";

import Layout from "../../layouts/layout";
import { Brands } from "../../components/Brands";
import { ContentCentered } from "../../components/ContentCentered";
import { GetStartedBox } from "../../components/GetStartedBox";
import { HeaderTitle } from "../../components/HeaderTitle";
import { WavySeparator } from "../../components/WavySeparator";

import * as helperStyles from "../helpers.module.css";
import { Link } from "gatsby-plugin-intl";
import { CategoryLinkCard } from "../../components/CategoryLinkCard";
import { GatsbyImage } from "gatsby-plugin-image";

export default function Templates({ data }) {
  return (
    <Layout
      activeMenuItem={"resources/user-testing-templates"}
      title={"Templates"}
      metaDescription={`Knowing what and how to test can be a challenge. That's why we've created an extensive template library for inspiration.`}
    >
      <article>
        <HeaderTitle
          overline={"User testing templates"}
          subtitle={`Ready to find out what your users are really thinking? Our usability testing templates make it easy to gather feedback, understand your users' needs, and quickly identify any usability hitches. Get inspired by our user testing examples and improve your product’s UX.`}
          subtitleWidth={"36em"}
        >
          <>
            <span className={helperStyles.h1Serif}>User testing</span>
            <br /> <strong className={helperStyles.underline}>
              templates
            </strong>{" "}
            <span className={helperStyles.h1Serif}>and examples</span>
          </>
        </HeaderTitle>

        <WavySeparator style={{ paddingTop: "var(--spacing-md)" }} />

        <div className={cn(helperStyles.container, helperStyles.spacingMd)}>
          <Headline3Sans>Browse by Categories</Headline3Sans>
          <CategoryLinkCard.Group className={helperStyles.spacingSm}>
            {data.allStrapiTemplateCategoryPage.edges.map(
              ({ node: categoryPage }) => {
                return (
                  <CategoryLinkCard
                    key={categoryPage.id}
                    title={categoryPage.example_category.title}
                    subtitle={`${categoryPage.example_category.examples.length} templates`}
                    linkTo={`/user-testing-templates/categories/${categoryPage.example_category.url}`}
                    staticImage={(className) => (
                      <GatsbyImage
                        className={className}
                        alt={`${categoryPage.example_category.title} like environment`}
                        image={
                          categoryPage.TeaserImage.localFile.childImageSharp
                            .gatsbyImageData
                        }
                      />
                    )}
                  />
                );
              }
            )}
          </CategoryLinkCard.Group>
        </div>

        <div className={helperStyles.container}>
          <ContentCentered
            className={helperStyles.spacingLg}
            title={"Our user testing template library"}
            titleWidth={"16em"}
            textContent={
              "Browse through our extensive library of 50+ user testing tasks, questions, scenarios, tips & tricks."
            }
          />
          <div style={{ textAlign: "center" }}>
            <ButtonPrimaryLarge
              className={helperStyles.spacingSm}
              customTag={Link}
              to={"/user-testing-template-library"}
            >
              View all templates
            </ButtonPrimaryLarge>
          </div>
        </div>

        <div className={helperStyles.container}>
          <GetStartedBox className={helperStyles.spacingLg} />
          <Brands className={helperStyles.spacingMd} />
        </div>
      </article>
    </Layout>
  );
}

export const query = graphql`
  query {
    allStrapiTemplateCategoryPage {
      edges {
        node {
          id
          TeaserImage {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 800
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
          example_category {
            title
            url
            examples {
              id
            }
          }
        }
      }
    }
  }
`;
